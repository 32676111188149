import { createInertiaApp } from '@inertiajs/react';
import { StrictMode } from 'react';
import { I18nProvider } from 'react-aria-components';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';

import StyleReset from './components/StyleReset/StyleReset';
import ThemeProvider from './components/ThemeProvider/ThemeProvider';
import PageProps from './customer-area/types/Inertia/PageProps';
import { ConfirmDialogProvider } from './hooks/ui/useConfirmDialog';

const pages = import.meta.glob('./customer-area/pages/{*.tsx,*/*.tsx}');

createInertiaApp<PageProps>({
    progress: {
        delay: 250,
        color: '#12C19B',
        includeCSS: true,
        showSpinner: false,
    },
    resolve: (name) => {
        const page = pages[`./customer-area/pages/${name}.tsx`];
        if (!page) {
            throw new Error(`Cannot find page with name "${name}"`);
        }

        return page();
    },
    setup: ({ el, App, props }) => {
        const { localization, theme } = props.initialPage.props;

        createRoot(el).render(
            <StrictMode>
                <StyleReset>
                    <ThemeProvider theme={theme}>
                        <IntlProvider
                            messages={localization.translations}
                            locale={localization.currentLocale.ietfLanguageTag}
                        >
                            <I18nProvider
                                locale={
                                    localization.currentLocale.ietfLanguageTag
                                }
                            >
                                <ConfirmDialogProvider>
                                    <App {...props} />
                                </ConfirmDialogProvider>
                            </I18nProvider>
                        </IntlProvider>
                    </ThemeProvider>
                </StyleReset>
            </StrictMode>
        );
    },
});
